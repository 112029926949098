<template>
  <div class="flex-container">
  </div>
</template>
<script>

import { env } from '../../env.js'

export default {

  data: function () {
    return {

    }
  },
  methods: {
    _criarCookieAcesso () {
      const d = new Date()
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000))
      const expires = ';expires=' + d.toUTCString()
      const domain = ';domain=' + env.domain
      const name = 'JWT='
      const bearer = 'Bearer '
      const value = this.$route.params.jwt
      const path = ';path=/'
      document.cookie = name + bearer + value + expires + domain + path
      const conteudo = value.split('.')[1]
      const decodedStr = atob(conteudo)
      const decodedStrWithUTF8 = decodeURIComponent(escape(decodedStr))
      const objToken = JSON.parse(decodedStrWithUTF8)

      document.cookie = 'Usuario' + '=' + objToken['desktop-login'] + ';' + expires + domain + path
    },

    _ajustarURLAcesso () {
      window.location.href = '/inicio'
    }
  },

  mounted () {
    if (this.$route.params.jwt) {
      this._criarCookieAcesso()
      this._ajustarURLAcesso()
    } else {
      window.location.href = '/erroAcesso'
    }
  }

}
</script>
<style scoped>
  .flex-container {
    display: flex;
  }

  h1 {
    color: black;
  }

</style>
